import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ExperienceItem from "../components/experience/ExperienceItem"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => {
  const { resume } = useStaticQuery(graphql`
    query AboutQuery {
      resume: file(relativePath: { eq: "resume.pdf" }) {
        publicURL
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="About"
        description="Hey there! I'm Miguel Gallardo, I'm a developer from Mexico currently
        working at IBM as a Full Stack Developer."
      />
      <h1>About me</h1>
      <p>
        Hey there! I'm Miguel Gallardo, I'm a developer from Mexico currently
        working at IBM as a Full Stack Developer.
      </p>
      <p>
        I was born and raised in Mexico City, where I went to school at
        Tecnológico de Monterrey, graduating with a degree in Computer Science.
        When I'm not coding, you can find me playing soccer and PC Games,
        cooking steaks, or watching other people doing all of those things on
        Youtube.
      </p>
      <p>
        If you have any question or would like to know more about what I do,
        don't hesitate asking me. I'm always open to chat about anything you'd
        like.
      </p>
      <h4 className="text-gray-800 dark:text-brand-400 text-2xl font-sans mt-8 font-bold mb-4">
        My Experience
      </h4>
      <p>
        Here, you'll find a brief summarry of my work experience. If you want to
        take a look at my complete Resume, you can download it in full{" "}
        <a
          href={resume.publicURL}
          download
          className="font-medium underline text-gray-900 dark:text-brand-400"
        >
          here.
        </a>
      </p>
      <div className="border-l border-brand-300 mb-8 relative">
        <ExperienceItem
          company="Anyfin"
          startDate="Mar 2022"
          endDate="Present"
          title="Full Stack Engineer"
          description={[
            "I work at Anyfin, focusing on increasing customer conversions for our main product (Loan refinancing) through implementing product designs and setting up AB tests using Typescript, React and React Native.",
          ]}
        ></ExperienceItem>
        <ExperienceItem
          company="IBM"
          startDate="Feb 2021"
          endDate="Mar 2022"
          title="Full Stack Developer"
          description={[
            "I worked on the IBM CIO, focusing mainly on the development and continuous improvement of internal systems that are used by key decision makers inside the company. Some of the things I've worked on include developing web applications with Angular and Python and improving internal testing within my team.",
          ]}
        ></ExperienceItem>
        <ExperienceItem
          company="Inowu"
          startDate="Nov 2018"
          endDate="Feb 2021"
          title="Full Stack Engineer"
          description={[
            "Developed web applications using React as a Front End Framework. Integrating with external services like Stripe, Sendgrid, Firebase, and Vimeo.",
            "Development of RESTful APIs using Node JS, Express, and database systems like MongoDB and MySQL. Serverless REST APIs using AWS Lambda, RDS, or DynamoDB and API Gateway.",
          ]}
        ></ExperienceItem>
        <ExperienceItem
          company="Especialidades Médicas Tepepan"
          startDate="Aug 2018"
          endDate="Nov 2018"
          title="Front End Developer"
          description={[
            "Developed an online calendar tool that automates medical appointment booking using React and Firebase. Designed and developed a static website using Gatsby JS.",
          ]}
        ></ExperienceItem>
      </div>
    </Layout>
  )
}

export default About
